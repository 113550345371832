import { createStore } from 'vuex';

import userInformationModule from './user/modules/information/index.js';
import contactMeModule from './user/modules/contactMe/index.js';

const store = createStore({
  modules: {
    userInformation: userInformationModule,
    contactMe: contactMeModule
  }
});

export default store;