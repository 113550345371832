<template>
  <section class="contact section" id="contact">
    <h2 class="section__title">Contact Me</h2>
    <span class="section__subtitle">Get in touch</span>

    <div class="contact__container user-container grid">
      <div>
        <div class="contact__information">
          <i class="uil uil-phone contact__icon"></i>
          <div>
            <h3 class="contact__title">Call Me</h3>
            <span class="contact__subtitle">{{ datas.phone }}</span>
          </div>
        </div>
        <div class="contact__information">
          <i class="uil uil-envelope contact__icon"></i>
          <div>
            <h3 class="contact__title">Email</h3>
            <span class="contact__subtitle"><a v-bind:href="'mailto:' + datas.email">{{ datas.email }}</a></span>
          </div>
        </div>
        <div class="contact__information">
          <i class="uil uil-map-marker contact__icon"></i>
          <div>
            <h3 class="contact__title">Address</h3>
            <span class="contact__subtitle">{{ datas.address }}</span>
          </div>
        </div>
      </div>

      <form class="contact__form grid" @submit.prevent="submit">
        <div class="contact__inputs grid">
          <div class="contact__contact">
            <label for="name" class="contact__label">Name</label>
            <input type="text" class="contact__input" v-model="name" id="name">
            <div class="error-message" v-if="v$.name.$error">
              {{ v$.name.$errors[0].$message }}
            </div>
          </div>
          <div class="contact__contact">
            <label for="email" class="contact__label">Email</label>
            <input type="email" class="contact__input" v-model="email" id="email">
            <div class="error-message" v-if="v$.email.$error">
              {{ v$.email.$errors[0].$message }}
            </div>
          </div>
        </div>
        <div class="contact__contact">
          <label for="message" class="contact__label">Message</label>
          <textarea name="message" id="message" cols="0" rows="7" v-model="message" class="contact__input"></textarea>
          <div class="error-message" v-if="v$.message.$error">
            {{ v$.message.$errors[0].$message }}
          </div>
        </div>

        <div>
          <button type="submit" class="button button--flex" :disabled="isFromSubmit == 1">
            <div class="spinner-border" role="status" v-if="isFromSubmit == 1" style="margin-right: 10px">
              <span class="sr-only">Loading...</span>
            </div>
            Send Message
            <i class="uil uil-message button__icon"></i>
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
  import useValidate from '@vuelidate/core';
  import { required, email, helpers } from '@vuelidate/validators';

  export default {
    data() {
      return {
        v$: useValidate(),
        name: '',
        email: '',
        message: '',
        isFromSubmit: 0
      }
    },
    computed: {
      datas() {
        let contactMe = this.$store.getters['userInformation/getInformation'];
        
        return contactMe;
      }
    },
    validations() {
      return {
        name: {
          required: helpers.withMessage('User Name is required', required),
        },
        email: {
          required: helpers.withMessage('Email is required', required),
          email: helpers.withMessage('Please enter valid email', email)
        },
        message: {
          required: helpers.withMessage('Message is required', required),
        }
      }
    },
    methods: {
      async submit() {
        try {
          this.v$.$validate();
          if (!this.v$.$error) {
            this.isFromSubmit = 1
            const payload = {
              name: this.name,
              email: this.email,
              message: this.message
            };
            let res = await this.$store.dispatch('contactMe/contactMe', payload);
            this.$toast.success(res.data.msg);
            this.isFromSubmit = 0;
            window.location.reload();
          }
        } catch (error) {
          console.log("Something went wrong. Please try again letter!" + error);
        }
      }
    }
  };
</script>


<style scoped>
.contact__container {
    row-gap: 3rem;
}

.contact__information {
    display: flex;
    margin-bottom: var(--mb-2);
}

.contact__icon {
    font-size: 2rem;
    color: var(--first-color);
    margin-right: var(--mb-0-75);
}

.contact__title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
}

.contact__subtitle {
    font-size: var(--small-font-size);
    color: var(--text-color-light);
}

.contact__contact {
    background-color: var(--input-color);
    border-radius: .5rem;
    padding: .75rem 1rem .25rem;
}

.contact__label {
    font-size: var(--small-font-size);
    color: var(--title-color);
}

.contact__input {
    width: 100%;
    background-color: var(--input-color);
    color: var(--text-color);
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid var(--first-color);
    outline: none;
    padding: .25rem .5rem .5rem 0;
}

@media screen and (min-width: 568px) {
  .contact__container {
      grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .contact__form {
    width: 460px;
  }
  .contact__inputs {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>