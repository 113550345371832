<template>
  <section class="skills section" id="skills">
    <h2 class="section__title">Skills</h2>
    <span class="section__subtitle">My technical level</span>

    <div class="skills__container user-container grid">
        <skill-items v-for="skill in datas" :key="skill.id"
          :firstSkillId = datas[0].id
          :skillId = skill.id
          :skillsTitle = skill.skillsTitle
          :year = skill.experienceYear
          :skills = skill.skills
          :icon = skill.icon></skill-items>
    </div>
  </section>
</template>

<script>
import SkillItems from '../../components/User/Skills/Items.vue'

export default {
  components: {
    SkillItems
  },
  data() {
    return {
      
    }
  },
  computed: {
    datas() {
      let skills = this.$store.getters['userInformation/getInformation'];
      
      return skills.skills;
    }
  }
}
</script>

<style scoped>
.skills__container {
  row-gap: 0;
}

@media screen and (min-width: 568px) {
  .skills__container {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>