import API from "./API.js";

export default {
  getInformation() {
    return API().get('get-information');
  },
  contactMe(payload) {
    return API().post('send-contact-email', {
      ...payload
    });
  }
}