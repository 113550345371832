<template>
  <qualification-items v-for="(data, index) in datas" 
    :key="index"
    :title="data.title"
    :subtitle="data.subtitle"
    :starting_month="data.starting_month"
    :starting_year="data.starting_year"
    :ending_month="data.ending_month"
    :ending_year="data.ending_year"
    :ranking="(!data.ranking) ? null : data.ranking"
    :ranking_word="(!data.ranking_word) ? null : data.ranking_word"
    :description="data.description"
    :dataKey="index"
    :skills="data.skills"
    :length="datas.length"
    :type="type"
    @work-detail="workDetail"></qualification-items>

    <div class="services__modal" :class="{'active-modal': openModel}">
      <div class="services__modal-content">
          <h4 class="services__modal-title">
            {{ workTitle }}
          </h4>
          <i class="uil uil-times services__modal-close" @click="closeWorkDetail"></i>
          <ul class="services__modal-services grid">
              <li class="services__modal-service" v-for="(workDetail, key) in workDetails" :key="key">
                  <i class="uil uil-check-circle services__modal-icon"></i>
                  <p>{{ workDetail }}</p>
              </li>
          </ul>
      </div>
    </div>
</template>

<script>
import QualificationItems from '../../components/User/Qualification/QualificationItems.vue'

export default {
  props: [
    'type'
  ],
  emits: ['type'],
  components: {
    QualificationItems
  },
  data() {
    return {
      isLoading: false,
      error: null,
      openModel: false,
      workDetails: [],
      workTitle: ''
    }
  },
  methods: {
    // async loadData() {
    //   console.log("here 1");
    //   this.isLoading = true;
    //   try {
    //     if (this.type == 'work') {
    //       await this.$store.dispatch('userExperience/getExperienceList');
    //     } else {
    //       await this.$store.dispatch('userQualification/getQualificationList');
    //     }
    //   } catch (error) {
    //     this.error = error.response.message || 'Something went wrong!';
    //   }
    //   this.isLoading = false;
    // },
    workDetail(value, title) {
      this.openModel = true;
      this.workTitle = title;
      this.workDetails = value.split('<br />');
    },
    closeWorkDetail() {
      this.openModel = false;
    }
  },
  computed: {
    datas() {
      let typeData = this.$store.getters['userInformation/getInformation'];

      if (this.type == 'work') {
        if (typeData.experience.length === 0)
          return [];

        return typeData.experience;
      } else {
        if (typeData.education.length === 0)
          return [];
          
        return typeData.education;
      }
    }
  },
  // watch: {
  //   type() {
  //     this.loadData();
  //   },
  // },
  created() {
    this.$emit('type', this.type);
    // this.loadData();
  }
}
</script>

<style scoped>
.services__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: var(--z-modal);
  opacity: 0;
  visibility: hidden;
  transform: .3s;
}

.services__modal-content {
  position: relative;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: .5rem;
}

.services__modal-services {
  row-gap: 1rem;
  padding-left: 0 !important;
  width: auto;
  max-height: 400px;
  overflow-y: auto;
}

.services__modal-service {
  display: flex;
}

.services__modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
}

.services__modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--first-color);
  cursor: pointer;
}

.services__modal-icon {
  color: var(--first-color);
  margin-right: var(--mb-0-25);
}

li.services__modal-service > p {
  margin: 0 !important;
}

/* Active Modal */
.active-modal {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 350px) {
  .services__modal {
    padding: 0 .5rem;
  }
}

@media screen and (min-width: 768px) {
  .services__modal-content {
    width: 450px;
  }
}

</style>
