<template>
  <section class="qualification section">
    <h2 class="section__title">Qualification & Experience</h2>    
    <span class="section__subtitle">My personal journey</span>

    <div class="qualification__container user-container">
      <div class="qualification__tabs">
        <router-link to="education" class="qualification__button button--flex" @click="selectedType('education')" :class="{'qualification__active': type == 'education'}">
          <i class="uil uil-graduation-cap qualification__icon"></i>
          Education
        </router-link>
        <router-link to="work" class="qualification__button button--flex" @click="selectedType('work')" :class="{'qualification__active': type == 'work'}">
          <i class="uil uil-briefcase-alt qualification__icon"></i>
          Work
        </router-link>
      </div>
      <router-view @type="selectedType"></router-view>
    </div>
  </section>  
</template>

<script>
export default {
  data() {
    return {
      type: ''
    }
  },
  methods: {
    selectedType(value) {
      this.type = value;
    }
  }
}
</script>

<style scoped>
.qualification__tabs {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: var(--mb-2);
}

.qualification__button {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  cursor: pointer;
}

.qualification__button:hover {
  color: var(--first-color);
}

.qualification__icon {
  font-size: 1.8rem;
  margin-right: var(--mb-0-25);
}

.qualification__button.qualification__active {
  color: var(--first-color) !important;
}

a {
  color: var(--text-color) !important;
}
</style>