<template>
  <section class="about section" id="about">
    <h2 class="section__title">About Me</h2>
    <span class="section__subtitle">My introduction</span>

    <div class="about__container user-container grid">
      <!-- <img src="https://media-exp1.licdn.com/dms/image/C4E03AQGIfOBTFhjpyw/profile-displayphoto-shrink_800_800/0/1633058557179?e=1650499200&v=beta&t=5kaew0AnhljxJwE9sSIOuayGT4c6W5dCYdv8bT4tgbI" alt="" class="about__img"> -->
      <p class="about__description" v-html="information.about"></p>
      <div class="about__data">
          <!-- <p class="about__description" v-html="information.about"></p> -->
          <div class="about__info">
              <div>
                  <span class="about__info-title">{{ information.experience }}</span>
                  <span class="about__info-name">Year<br> experience</span>
              </div>
              <div>
                  <span class="about__info-title">{{ information.project_completed }}</span>
                  <span class="about__info-name">Completed<br> project</span>
              </div>
              <div>
                  <span class="about__info-title">{{ information.companies_work }}</span>
                  <span class="about__info-name">Companies<br> work</span>
              </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    information() {
      if (this.$store.getters['userInformation/getInformation'].length === 0)
        return [];
        
      return this.$store.getters['userInformation/getInformation'];
    }
  }
  // created() {
  //   this.getInformation();
  // },
}
</script>

<style scoped>
.about__img {
  width: 200px;
  border-radius: .5rem;
  justify-self: center;
  align-self: center;
}

.about__description {
  text-align: center;
  margin-bottom: var(--mb-2-5);
}

.about__info {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: var(--mb-2-5);
}

.about__info-title {
  font-size: var(--h2-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.about__info-name {
  font-size: var(--small-font-size);
}

.about__info-title,
.about__info-name {
  display: block;
  text-align: center;
}

.about__buttons {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 568px) {
  .about__container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 768px) {
  .about__container {
    column-gap: 5rem;
  }
  .about__img {
    width: 350px;
  }
  .about__description {
    text-align: inherit;
  }
  .about__info {
    justify-content: space-between;
  }
  .about__buttons {
    justify-content: initial;
  }
}
</style>