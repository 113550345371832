<template>
  <header class="header scroll-header" id="header">
    <nav class="user-nav user-container">
        <a href="#" class="nav__logo">Jigar</a>
        <div class="nav__menu" :class="{'show-menu': showMenu}" id="nav-menu">
            <ul class="nav__list grid">
                <li class="nav__item" @click="showHideMenu">
                    <router-link to="/" class="nav__link">
                        <i class="uil uil-estate nav__icon"></i> About
                    </router-link>
                </li>
                <!-- <li class="nav__item" @click="showHideMenu">
                    <router-link to="/about" class="nav__link">
                        <i class="uil uil-user nav__icon"></i> About
                    </router-link>
                </li> -->
                <li class="nav__item" @click="showHideMenu">
                    <router-link to="/skill" class="nav__link">
                        <i class="uil uil-file-alt nav__icon"></i> Skills
                    </router-link>
                </li>
                <li class="nav__item" @click="showHideMenu">
                    <router-link to="/qualification-experience/education" class="nav__link">
                        <i class="uil uil-bag-alt nav__icon"></i>
                        Qualification & Experience
                    </router-link>
                </li>
                <li class="nav__item" @click="showHideMenu">
                    <router-link to="/portfolio" class="nav__link">
                        <i class="uil uil-scenery nav__icon"></i> Portfolio
                    </router-link>
                </li>
                <li class="nav__item" @click="showHideMenu">
                    <router-link to="/contact-me" class="nav__link">
                        <i class="uil uil-message nav__icon"></i> Contact me
                    </router-link>
                </li>
            </ul>
            <i class="uil uil-times nav__close" id="nav-close" @click="showHideMenu"></i>
        </div>
        <div class="nav__btns">
            <i class="uil change-theme" :class="{'uil-sun': this.darkThem, 'uil-moon': !this.darkThem}" id="theme-button" @click="changeThem"></i>

            <div class="nav__toggle" id="nav-toggle" @click="showHideMenu">
                <i class="uil uil-apps"></i>
            </div>
        </div>
    </nav>
  </header>
</template>

<script>
export default {
    data() {
        return {
            showMenu: false,
            darkThem: false
        }
    },
    methods: {
        showHideMenu() {
            this.showMenu = !this.showMenu;
        },
        changeThem() {
            this.darkThem = !this.darkThem;
            if (this.darkThem) {
                document.body.classList.add('dark-theme');
            } else {
                document.body.classList.remove('dark-theme');
            }
        }
    },
}
</script>

<style>
/*==================== VARIABLES CSS ====================*/
:root {
    --header-height: 3rem;

    /*========== Colors ==========*/
    /* Change favorite color */
    --hue-color: 230; /*Purple 250 - Green 142 - Blue 230 - Pink 340*/

    /* HSL color mode */
    --first-color: hsl(var(--hue-color), 69%, 61%);
    --first-color-second: hsl(var(--hue-color), 69%, 61%);
    --first-color-alt: hsl(var(--hue-color), 57%, 53%);
    --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
    --title-color: hsl(var(--hue-color), 8%, 15%);
    --text-color: hsl(var(--hue-color), 8%, 45%);
    --text-color-light: hsl(var(--hue-color), 8%, 65%);
    --input-color: hsl(var(--hue-color), 70%, 96%);
    --body-color: hsl(var(--hue-color), 60%, 99%);
    --submenu-body-color: hsl(var(--hue-color), 60%, 99%);
    --container-color: #FFF;
    --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
    --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);

    /*========== Font and typography ==========*/
    --body-font: 'Poppins', sans-serif;

    /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
    --big-font-size: 2rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1.125rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;

    /*========== Font weight ==========*/
    --font-medium: 500;
    --font-semi-bold: 600;

    /*========== Margenes Bottom ==========*/
    /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
    --mb-0-25: .25rem;
    --mb-0-5: .5rem;
    --mb-0-75: .75rem;
    --mb-1: 1rem;
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-5: 2.5rem;
    --mb-3: 3rem;

    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 100;
}

body.dark-theme {
    /* HSL color mode */
    --first-color-second: hsl(var(--hue-color), 30%, 8%);
    --title-color: hsl(var(--hue-color), 8%, 95%);
    --text-color: hsl(var(--hue-color), 8%, 75%);
    --input-color: hsl(var(--hue-color), 29%, 16%);
    --body-color: hsl(var(--hue-color), 28%, 12%);
    --submenu-body-color: hsl(var(--hue-color), 37%, 32%);
    --container-color: hsl(var(--hue-color), 29%, 16%);
    --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
    --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
}

/*========== Button Dark/Light ==========*/
.nav__btns {
    display: flex;
    align-items: center;
}

.change-theme {
    font-size: 1.25rem;
    color: var(--title-color);
    margin-right: var(--mb-1);
    cursor: pointer;
}

.change-theme:hover {
    color: var(--first-color);
}

/*==================== BASE ====================*/
*{
    box-sizing: border-box !important;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth !important;
}

body {
    margin: 0 0 var(--header-height) 0 !important;
    font-family: var(--body-font) !important;
    font-size: var(--normal-font-size) !important;
    background-color: var(--body-color) !important;
    color: var(--text-color) !important;
}

h1, h2, h3, h4 {
    color: var(--title-color) !important;
    font-weight: var(--font-semi-bold) !important;
}

ul {
    list-style: none;
}

a {
    text-decoration: none !important;
}

a:active,
a:hover,
a.router-link-active {
    color: var(--first-color);
}

img {
    max-width: 100%;
    height: auto;
}

/*==================== REUSABLE CSS CLASSES ====================*/
.section {
    padding: 2rem 0 4rem;
}

.section__title {
    font-size: var(--h1-font-size);
}

.section__subtitle {
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-3);
}

.section__title,
.section__subtitle {
    text-align: center;
}

/*==================== LAYOUT ====================*/
.user-container {
    max-width: 1000px;
    margin-left: var(--mb-1-5) !important;
    margin-right: var(--mb-1-5) !important;
}

.grid {
    display: grid;
    gap: 1rem;
}

.header {
    width: 100%;
    position: fixed;
    bottom: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
}

/*==================== NAV ====================*/
.dropdown-item {
    color: var(--title-color) !important;
    font-size: var(--small-font-size);
}

.dropdown-item:hover {
    background-color: var(--submenu-body-color) !important;
    border-radius: 10px !important;
    font-size: var(--small-font-size);
    color: var(--first-color) !important;
}

.dropdown-menu {
    border-radius: 1rem;
    margin-top: 10px !important;
    margin-left: -20px !important;
    border-radius: 1rem !important;
    /* display: block !important; */
    background-color: var(--submenu-body-color) !important;
}

.user-nav {
    max-width: 1020px;
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__logo,
.nav__toggle {
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav__logo:hover {
    color: var(--first-color);
}

.nav__toggle {
    font-size: 1.1rem;
    cursor: pointer;
}

.nav__toggle:hover {
    color: var(--first-color);
}

@media screen and (max-width: 767px) {
    .nav__menu {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--body-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, .15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;
    }
}

.nav__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 0 !important;
    padding-left: 0 !important;
}

.nav__link {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-size);
    color: var(--title-color) !important;
    font-weight: var(--font-medium);
    text-align: center;
}

.nav__link:hover {
    color: var(--first-color) !important;
}

.nav__link.router-link-exact-active {
    color: var(--first-color) !important;
}

.nav__icon {
    font-size: 1.2rem;
}

.nav__close {
    position: absolute;
    right: 1.3rem;
    bottom: .5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--first-color);
}

.nav__close:hover {
    color: var(--first-color-alt);
}

/* show menu */
.show-menu {
    bottom: 0;
}

/* Active link */
.active-link {
    color: var(--first-color);
}

/* Change background header */
.scroll-header {
    box-shadow: 0 -1px 4px rgba(0, 0, 0, .15);
}

/*==================== BUTTONS ====================*/
.button {
    display: inline-block;
    background-color: var(--first-color);
    color: #FFF;
    padding: 1rem;
    border-radius: .5rem;
    font-weight: var(--font-medium);
}
.button:hover {
    background-color: var(--first-color-alt);
    color: #FFF !important;
}

.button__icon {
    font-size: 1.25rem;
    margin-left: var(--mb-0-5);
    transition: .3s;
}

.button--white {
    background-color: #fff;
    color: var(--first-color);
}

.button--white:hover {
    background-color: #fff;
}

.button--flex {
    display: inline-flex;
    align-items: center;
}

.button--small {
    padding: .75rem 1rem;
}

.button--link {
    padding: 0;
    background-color: transparent;
    color: var(--first-color);
}

.button--link:hover {
    background-color: transparent;
    color: var(--first-color-alt) !important;
}

/*========== SCROLL BAR ==========*/
::-webkit-scrollbar {
    width: 0.6rem;
    background-color: var(--scroll-bar-color);
    border-radius: .5rem;
}

::-webkit-scrollbar-thumb {
    background-color: var(--scroll-thumb-color);
    border-radius: .5rem;
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--text-color-light);
}

@media screen and (max-width: 350px) {
    .user-container {
        margin-left: var(--mb-1) !important;
        margin-right: var(--mb-1) !important;
    }

    .nav__menu {
        padding: 2rem .25rem 4rem;
    }
    .nav__list {
        column-gap: 0;
        margin-bottom: 0 !important;
        padding-left: 0 !important;
    }
}

@media screen and (min-width: 768px) {
    .user-container {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    body {
        margin: 0;
    }

    .section {
        padding: 6rem 0 2rem;
    }
    .section__subtitle {
        margin-bottom: 4rem;
    }

    .header {
        top: 0;
        bottom: inherit;
    }

    .header,
    .main-con,
    .footer__container {
        padding: 0 1rem;
    }

    .user-nav {
        height: calc(var(--header-height) + 1.5rem);
        column-gap: 1rem;
    }
    .nav__icon,
    .nav__close,
    .nav__toggle {
        display: none;
    }
    .nav__list {
        display: flex;
        column-gap: 2rem;
    }
    .nav__menu {
        margin-left: auto;
    }
    .change-theme {
        margin: 0;
    }
}

@media screen and (min-width: 1024px) {
    .header,
    .main-con,
    .footer__container {
        padding: 0;
    }
}
</style>