<template>
  <section class="portfolio section" id="portfolio">
    <h2 class="section__title">Portfolio</h2>
    <span class="section__subtitle">Most recent work</span>

    <div class="portfolio__container user-container swiper">
        <div class="swiper-wrapper">
            <swiper
              :modules="modules"
              :slides-per-view="1"
              :space-between="50"
              navigation
              :pagination="{ clickable: true }"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
            >
              <swiper-slide v-for="(portfolio, index) in datas" :key="index" class="portfolio__content grid">
                <!-- <img :src="`https://picsum.photos/200/300?random=${n}`" class="portfolio__img"> -->
                <img :src="portfolio.project_image" class="portfolio__img">
                <!-- <image class="portfolio__img" v-bind:href="portfolio.project_image"/> -->

                <div class="portfoli__data">
                    <a v-bind:href="portfolio.project_url" target="_blank">
                      <h3 class="portfolio__title"><span class="title">{{ portfolio.project_name }}</span></h3>
                    </a>
                    <p class="portfolio__description" v-html="portfolio.project_description"></p>
                </div>
              </swiper-slide>
            </swiper>
        </div>
    </div>
  </section>
</template>
<script>
  import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

  import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";

  import "swiper/swiper-bundle.css";

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Navigation, Pagination, Scrollbar, A11y],
      };
    },
    computed: {
      datas() {
        let portfolio = this.$store.getters['userInformation/getInformation'];
        
        return portfolio.portfolios;
      }
    }
  };
</script>

<style>
.portfolio__container {
  overflow: initial;
}

.portfolio__content {
    padding: 0 1.5rem;
}

.portfolio__img {
    width: 265px;
    border-radius: .5rem;
    justify-self: center;
}

.portfolio__title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-0-5);
  color: var(--first-color) !important;
}

.title {
  border-bottom: 1px solid var(--first-color);
}

.portfolio__description {
  margin-bottom: var(--mb-0-75);
  height: 300px;
  overflow-y: scroll;
  text-align: justify;
  padding-right: 10px;
}

.portfolio__button:hover .button__icon {
    transform: translateX(.25rem);
}

.portfoli__data {
  margin-bottom: 2.6rem;
}

.swiper-button-prev {
  left: -.5rem;
}

.swiper-button-next {
  right: -.2rem !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: var(--h3-font-size) !important;
  font-weight: bolder !important;
}
/* .swiper-button-prev::after,
.swiper-button-next::after {
    content: '' !important;
} */

/* .swiper-portfolio-icon {
    font-size: 2rem;
    color: var(--first-color);
}

.swiper-button-prev {
  left: -.5rem;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -2.5rem;
}

.swiper-pagination-bullets-active {
    background-color: var(--first-color);
}

.swiper-button-prev,
.swiper-button-next,
.swiper-pagination-bullets {
    outline: none !important;
} */

@media screen and (min-width: 568px) {
  .portfolio__content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 768px) {
  .portfolio__img {
    width: 320px;
  }
  .portfolio__content {
    align-items: center;
  }
}

@media screen and (min-width: 1024px) {
  .portfolio__content {
    column-gap: 5rem;
  }
}
</style>