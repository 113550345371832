<template>
  <div class="skills__content" :class="isOpenId === skillId ? 'skills__open' : 'skills__close'">
    <div class="skills__header" @click="skillOpen(skillId)">
        <i class="uil skills__icon" :class="icon"></i>

        <div>
            <h1 class="skills__title">{{ skillsTitle }}</h1>
            <span class="skills__subtitle">More then {{ year }} years</span>
        </div>

        <i class="uil uil-angle-down skills__arrow"></i>
    </div>

    <div class="skills__list grid" v-for="skill in skills" :key="skill.id">
        <div class="skills__data">
            <div class="skills__title" style="padding: 10px 10px 0px 10px">
                <h3 class="skills__name">{{ skill.name }}</h3>
                <span class="skills__expertise">{{ skill.expertise }}</span>
            </div>
            <!-- <div class="skills__bar">
                <span class="skills__percentage skills__laravel"></span>
            </div> -->
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props : [
    'skillsTitle',
    'year',
    'skills',
    'skillId',
    'firstSkillId',
    'icon'
  ],
  data() {
    return {
      isOpenId: this.firstSkillId,
    }
  },
  created() {
    return this.firstSkillId;
  },
  methods: {
    skillOpen(id) {
      this.isOpenId = (id !== this.isOpenId) ? id : null;
    }
  },
}
</script>

<style scoped>
.skills__header {
    display: flex;
    align-items: center;
    margin-bottom: var(--mb-1);
    cursor: pointer;
}

.skills__icon,
.skills__arrow {
    font-size: 2rem;
    color: var(--first-color);
}

.skills__icon {
    margin-right: var(--mb-0-75);
}

.skills__title {
    font-size: var(--h3-font-size);
}

.skills__subtitle {
    font-size: var(--small-font-size);
    color: var(--text-color-light);
}

.skills__arrow {
    margin-left: auto;
    transition: .4s;
}

.skills__list {
    row-gap: 1.5rem;
    padding-left: 2.7rem;
}
.skills__title {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: var(--mb-0-5); */
}

.skills__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.skills__expertise {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    color: var(--title-color) !important;
}

.skills__bar,
.skills__percentage {
    height: 5px;
    border-radius: .25px;
}

.skills__bar {
    background-color: var(--first-color-lighter);
}

.skills__percentage {
    display: block;
    background-color: var(--first-color);
}

.skills__laravel {
    width: 90%;
}

.skills__close .skills__list {
    height: 0;
    overflow: hidden;
}

.skills__open .skills__list {
    height: max-content;
    margin-bottom: var(--mb-1);
}

.skills__open .skills__arrow {
    transform: rotate(-180deg);
}

.skills__data {
  border: 2px dotted var(--first-color);
  border-radius: 10px;
}

@media screen and (max-width: 350px) {
  .skills__title {
    font-size: var(--normal-font-size);
  }
}
</style>