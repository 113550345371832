<template>
  <div class="loader">
    <img src="/loader.gif" style="width: 40px;">
  </div>
</template>

<style scoped>
.loader{
  position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
    color:darkred;
}
</style>