import { createApp } from 'vue';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { dom } from "@fortawesome/fontawesome-svg-core";
import Toaster from "@meforma/vue-toaster";

import App from './App.vue';
import TheUserHeader from './components/User/layout/TheUserHeader.vue';
import BaseCard from "./components/UI/BaseCard.vue";
import BaseSpinner from './components/UI/BaseSpinner.vue';
import SectionSpinner from './components/UI/SectionSpinner.vue';
import BaseDialog from './components/UI/BaseDialog.vue';
import BaseButton from './components/UI/BaseButton.vue';

import router from './router/index.js';
import store from './store/index.js';

import "bootstrap/dist/css/bootstrap.min.css";
dom.watch();
library.add(fas);

const app = createApp(App);

app.use(store);
app.use(router);
app.use(Toaster, {
    position: "top-right",
    duration: 3000
});

app.config.productionTip = false;

app.component('the-user-header', TheUserHeader);
app.component('base-card', BaseCard);
app.component('base-spinner', BaseSpinner);
app.component('section-spinner', SectionSpinner);
app.component('base-dialog', BaseDialog);
app.component('base-button', BaseButton);
app.component("font-awesome-icon", FontAwesomeIcon);

app.mount('#app');

import "bootstrap/dist/js/bootstrap.js";