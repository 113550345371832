<template>
  <section class="home section" id="home">
    <div class="home__container user-container grid" v-if="userInformation">
        <div class="home__header__content grid">
          <div>
            <h1 class="home__title">Hi, I am {{ information.name }}</h1>
            <h3 class="home__subtitle">{{ information.designation }}</h3>
          </div>
        </div>
        <!-- <h2 class="section__title">About Me</h2>
        <span class="section__subtitle">My introduction</span> -->
        <div class="home__content grid">
            <div class="home__social">
                <a v-bind:href="information.urls.git" class="home__social-icon" target="_blank">
                    <i class="uil uil-linkedin-alt"></i>
                </a>
                <a v-bind:href="information.urls.linkedin" class="home__social-icon" target="_blank">
                    <i class="uil uil-github-alt"></i>
                </a>
            </div>

            <div class="home__img">
              <svg class="home__blob" viewBox="0 0 200 187" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <mask id="mask0" mask-type="alpha">
                    <path d="M190.312 36.4879C206.582 62.1187 201.309 102.826 182.328 134.186C163.346 165.547 
                    130.807 187.559 100.226 186.353C69.6454 185.297 41.0228 161.023 21.7403 129.362C2.45775 
                    97.8511 -7.48481 59.1033 6.67581 34.5279C20.9871 10.1032 59.7028 -0.149132 97.9666 
                    0.00163737C136.23 0.303176 174.193 10.857 190.312 36.4879Z"/>
                </mask>
                <g mask="url(#mask0)">
                    <path d="M190.312 36.4879C206.582 62.1187 201.309 102.826 182.328 134.186C163.346 
                    165.547 130.807 187.559 100.226 186.353C69.6454 185.297 41.0228 161.023 21.7403 
                    129.362C2.45775 97.8511 -7.48481 59.1033 6.67581 34.5279C20.9871 10.1032 59.7028 
                    -0.149132 97.9666 0.00163737C136.23 0.303176 174.193 10.857 190.312 36.4879Z"/>

                    <image class="home__blob-img" x="20" y="14" v-bind:href="information.profileUrl"/>
                </g>
              </svg> 
              <div class="about__data">
                <div class="about__info">
                  <div>
                    <span class="about__info-title">{{ information.year_of_experience }}</span>
                    <span class="about__info-name">Year<br> experience</span>
                  </div>
                  <div>
                    <span class="about__info-title">{{ information.project_completed }}</span>
                    <span class="about__info-name">Completed<br> project</span>
                  </div>
                  <div>
                    <span class="about__info-title">{{ information.companies_work }}</span>
                    <span class="about__info-name">Companies<br> work</span>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="home__data">
                
                <p class="home__description" v-html="information.introduction"></p>
                <p class="home__description" v-html="information.about "></p>
                <!-- <a href="#contact" class="button button--flex">
                    Contact Me <i class="uil uil-message button__icon"></i>
                </a> -->
            </div>

            <!-- <div class="home__data">
              <p class="home__description" v-html="information.about "></p>
            </div> -->
        </div>

        <!-- <div class="home__scroll">
            <router-link to="/about" class="home__scroll-button button--flex">
              <i class="uil uil-mouse-alt home__scroll-mouse"></i>
              <span class="home__scroll-name">Scroll Down</span>
              <i class="uil uil-arrow-down home_scroll-arrow"></i>
            </router-link>
        </div> -->
    </div>
    <div v-else>
      <base-spinner />
    </div>
  </section>
</template>

<script>
import BaseSpinner from '../../components/UI/BaseSpinner.vue'

export default {
  data() {
    return {
      userInformation: null,
    }
  },
  components: {
    BaseSpinner
  },
  methods: {
    async getInformation() {
      try {
        this.userInformation = await this.$store.dispatch('userInformation/getInformation');
      } catch (error) {
        this.error = error.response.message || 'Something went wrong!';
      }
    }
  },
  computed: {
    information() {
      if (this.$store.getters['userInformation/getInformation']) {
        return this.$store.getters['userInformation/getInformation'];
      } else {
        return this.userInformation;
      }
    }
  },
  created() {
    this.getInformation();
  },
}
</script>

<style scoped>
h1, h2, h3, h4 {
  color: var(--title-color) !important;
  font-weight: var(--font-semi-bold) !important;
}

/* .home__container {
  gap: 1rem;
} */

.home__content {
  grid-template-columns: .5fr 3fr;
  /* padding-top: 3.5rem; */
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home__social-icon {
  font-size: 1.25rem;
  color: var(--first-color);
}

.home__social-icon:hover {
  color: var(--first-color-alt);   
}

.home__blob {
  width: 200px;
  fill: var(--first-color);
}

.home__blob-img {
  width: 170px;
}

.home__data {
  grid-column: 1/3;
}

.home__title {
  font-size: var(--big-font-size);
}

.home__subtitle {
  font-size: var(--h3-font-size) !important;
  color: var(--text-color) !important;
  font-weight: var(--font-medium) !important;
  margin-bottom: var(--mb-0-75) !important;
}

.home__description {
  text-align: justify;
  /* margin-bottom: var(--mb-1) !important; */
}

.home__scroll {
  display: none;
}

.home__scroll-button {
  color: var(--first-color);
  transition: .3s;
}

.home__scroll-button:hover {
  transform: translateY(.25rem);
}

.home__scroll-mouse {
  font-size: 2rem;
}

.home__scroll-name {
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25); 
}

.home__scroll-arrow {
  font-size: 1.25rem;
}

/* About */
.about__info {
  display: flex;
  justify-content: space-evenly;
  /* margin-bottom: var(--mb-2-5); */
}

.about__info-title {
  font-size: var(--h2-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.about__info-name {
  font-size: var(--small-font-size);
}

.about__info-title,
.about__info-name {
  display: block;
  text-align: center;
}

.about__buttons {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 350px) {
  .home__content {
    grid-template-columns: .25fr 3fr;
  }
  .home__blob {
    width: 180px;
  }
}

@media screen and (min-width: 568px) {
  .home__header__content {
    grid-template-columns: max-content 1fr 1fr;
  }
  .home__content {
    grid-template-columns: max-content 3fr 1fr;
  }
  .home__data {
    grid-column: initial;
  }
  .home__img {
    order: 1;
    justify-self: center;
  }

  /* About */
  .about__container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 768px) {
  .home__container {
    row-gap: 0rem;
  }
  .home__header__content {
    column-gap: 2rem;
  }
  
  /* .home__content { */
    /* padding-top: 5.5rem; */
    /* column-gap: 2rem; */
  /* } */

  .home__blob {
    width: 270px;
  }
  .home__scroll {
    display: block;
  }
  .home__scroll-button {
    margin-left: 3rem;
  }

  /* About */
  .about__container {
    column-gap: 5rem;
  }
  .about__img {
    width: 350px;
  }
  .about__description {
    text-align: inherit;
  }
  .about__info {
    justify-content: space-between;
  }
  .about__buttons {
    justify-content: initial;
  }
  
  .about__data {
    margin-top: 10px;
  }
}
</style>