<template>
    <div class="qualification__sections">
        <div class="qualification__content">
            <div class="qualification__data">
                <div v-if="dataKey % 2 !== 0"></div>
                <div v-if="dataKey % 2 !== 0">
                    <span class="qualification__rounder"></span>
                    <span class="qualification__line" v-if="dataKey + 1 !== length"></span>
                </div>
                <div class="qualification-data">
                    <h3 class="qualification__title">{{ title }} </h3>
                    <span class="qualification__subtitle">
                        {{ subtitle }}
                    </span>
                    <div class="qualification__ranking" v-if="ranking">
                        {{ ranking }}
                    </div>
                    <div class="qualification__calender">
                        <i class="uil uil-calendar-alt"></i>
                        {{ starting_year }} - <span v-if="!!ending_year">{{ ending_year }}</span><span v-else>Current
                            Working</span>
                    </div>
                    <div class="company-skills" v-if="type === 'work'">
                        <div class="badge bg-secondary company-skill" v-for="(skill, index) in JSON.parse(skills)"
                            :key="index" :style="{ 'color': skill.color, 'border-color': skill.color }">
                            {{ skill.skill }}
                        </div>
                    </div>
                    <span class="button button--flex button--small button--link services__button" v-if="type === 'work'"
                        @click="workDetail">
                        View more <i class="uil uil-arrow-right button__icon"></i>
                    </span>
                </div>
                <div v-if="dataKey % 2 === 0">
                    <span class="qualification__rounder"></span>
                    <span class="qualification__line" v-if="dataKey + 1 !== length"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'key',
        'title',
        'subtitle',
        'starting_month',
        'starting_year',
        'ending_month',
        'ending_year',
        'ranking',
        'ranking_word',
        'description',
        'dataKey',
        'length',
        'skills',
        'type'
    ],
    emits: ['work-detail'],
    methods: {
        workDetail() {
            this.$emit('work-detail', this.description, this.title);
        },
    },
}
</script>

<style scoped>
.qualification__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.qualification-data {
    border: 2px dotted var(--first-color);
    padding: 0.5rem;
    border-radius: 10px;
}

.qualification__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.qualification__subtitle {
    display: inline-block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-0-75);
}

.qualification__ranking {
    /* display: inline-block; */
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-0-75);
}

.qualification__calender {
    font-size: var(--small-font-size);
    color: var(--text-color-light);
}

.qualification__rounder {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: var(--first-color);
    border-radius: 50%;
}

.qualification__line {
    display: block;
    width: 2px;
    height: 100%;
    background-color: var(--first-color);
    transform: translate(6px, -8px);
}

.qualification [data-content] {
    display: none;
}

.qualification__active[data-content] {
    display: block;
}

.services__button {
    cursor: pointer;
    font-size: var(--small-font-size);
}

.services__button:hover .button__icon {
    transform: translateX(.25rem);
}

.button--link {
    padding: 0;
    background-color: transparent;
    color: var(--first-color);
    /* padding: 0rem 0.5rem 0rem 0.5rem; */
    margin-top: 0.6rem;
}

.button--link:hover {
    background-color: transparent;
    color: var(--first-color-alt);
}

.company-skills {
    display: grid;
    grid-template-columns: max-content 1fr 1fr;
}

.company-skill {
    margin: 10px 10px 0px 0px;
}

.bg-secondary {
    background-color: transparent !important;
    border: 1px solid red;
    color: red;
}

.qualification__sections {
    display: grid;
    justify-content: center;
}

/* iPads, Tablets */
/* @media screen and (min-width: 481px) and (max-width: 768px) {
    .company-skills {
        grid-template-columns: max-content !important;    
    }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
    .company-skills {
        grid-template-columns: max-content !important;    
    }

    .badge {
        white-space: unset !important;
    }

    .bg-secondary {
        width: 100px !important;
    }
} */

/* Mobile */
@media (max-width: 480px) {
    .company-skills {
        grid-template-columns: none !important;
    }

    .bg-secondary {
        width: 100px !important;
    }

    .badge {
        white-space: unset !important;
    }

    .qualification__data {
        gap: .4rem;
    }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 700px) and (max-width: 767px) {
    .company-skills {
        grid-template-columns: max-content 1fr 1fr !important;
    }

    .bg-secondary {
        width: 94px !important;
    }

    .company-skill {
        margin: 10px 5px 0px 0px;
    }
}

@media (min-width: 650px) and (max-width: 700px) {
    .company-skills {
        grid-template-columns: max-content 1fr 1fr !important;
    }

    .bg-secondary {
        width: 88px !important;
    }

    .company-skill {
        margin: 10px 3px 0px 0px;
    }
}

@media (min-width: 481px) and (max-width: 544px) {
    .company-skills {
        grid-template-columns: max-content 1fr !important;
    }

    .bg-secondary {
        width: 91px !important;
    }

    .company-skill {
        margin: 10px 3px 0px 0px;
    }

}

@media (min-width: 481px) and (max-width: 767px) {
    .company-skills {
        grid-template-columns: max-content 1fr;
    }

    .bg-secondary {
        width: 100px;
    }

    .badge {
        white-space: unset !important;
    }

    .qualification__data {
        gap: 2px !important;
    }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 767px) and (max-width: 930px){
    .bg-secondary {
        width: auto !important;
    }
    .company-skill {
        margin: 10px 3px 0px 0px;
    }
    .badge {
        white-space: unset !important;
    }

    .qualification__data {
        column-gap: 0.5rem;
    }
}

/* @media screen and (max-width: 480px) {
    .company-skills {
        grid-template-columns: none !important;
    }

    .bg-secondary {
        width: 100px !important;
    }

    .badge {
        white-space: unset !important;
    }
}

@media screen and (max-width: 350px) {
    .qualification__data {
        gap: .5rem;
    }

    .services__modal {
        padding: 0 .5rem;
    }
}

@media screen and (min-width: 568px) {
    .qualification__sections {
        display: grid;
        justify-content: center;
    }
}

@media screen and (min-width: 768px) {
    .qualification__tabs {
        justify-content: center;
    }
    .qualification__button {
        margin: 0 var(--mb-1);
    }

    .services__modal-content {
        width: 450px;
    }
} */
</style>