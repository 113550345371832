import {createRouter, createWebHistory} from 'vue-router';

import UserIndex from '../pages/user/Index.vue';
import UserAbout from '../pages/user/About.vue';
import UserSkills from '../pages/user/Skills.vue';
import UserQualificationExperience from '../pages/user/QualificationExperience.vue';
import QualificationExperienceDetail from '../pages/user/QualificationExperienceDetail.vue';
import UserPortfolio from '../pages/user/Portfolio.vue';
import UserContactMe from '../pages/user/ContactMe.vue';

// import store from '../store/index.js';

const router = createRouter({
  history: createWebHistory(),
  routes: [{
    path: '/',
    component: UserIndex,
    meta: {
      userType: "user"
    }
  }, {
    path: '/about',
    component: UserAbout,
    meta: {
      userType: "user"
    }
  }, {
    path: '/skill',
    component: UserSkills,
    meta: {
      userType: "user"
    }
  }, {
    path: '/qualification-experience',
    component: UserQualificationExperience,
    children: [{
      path: ':type',
      component: QualificationExperienceDetail,
      props: true,
    }],
    meta: {
      userType: "user"
    }
  }, {
    path: '/portfolio',
    component: UserPortfolio,
    meta: {
      userType: "user"
    }
  }, {
    path: '/contact-me',
    component: UserContactMe,
    meta: {
      userType: "user"
    }
  }]
});

export default router;